import Vue from 'vue'
import Router from 'vue-router'
//import store from "../store";
//import Swal from 'sweetalert2';
//import Api from '../service/api';

const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
   return routerPush.call( this , location).catch (error=> error)
}
Vue.use(Router)

function lazyLoad(view){
  return() => import(`@/components/${view}.vue`)
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      meta: { layout: "PublicVue" , header :'o-header--no-bg-color', middleware : 'check_domain'},
      component: require("@/components/public/auth/Login.vue").default // load sync home
    },
    {
      path: '/org-login/:id/:token',
      name: 'org-login',
      meta: { layout: "PublicVue" , header :'o-header--no-bg-color', middleware : 'check_domain'},
      component: require("@/components/public/auth/OrgListLogin.vue").default // load sync home
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      meta: { layout: "PublicVue" , header :'o-header--no-bg-color', middleware : 'check_domain'},
      component: require("@/components/public/auth/ForgotPassword.vue").default // load sync home
    },
    {
      path: '/change-password/:token',
      name: 'change-password',
      meta: { layout: "PublicVue" , header :'o-header--no-bg-color', middleware : 'check_domain'},
      component: require("@/components/public/auth/ChangePassword.vue").default // load sync home
    },
    {
      path: "/dashboard",
      name: "dashboard",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Dashboard' },
      component: lazyLoad('protected/Dashboard')
    },

    /* Goal Import */
    {
      path: '/goal-list',
      name: 'goal-list',
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Goal Import' },
      component: lazyLoad('protected/GoalImport/GoalList'),
    },
    {
      path: "/module-and-menu-setup",
      name: "module-and-menu-setup",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Module Settings' },
      component: lazyLoad('protected/ModuleMenu/ModuleAndMenuList')
    },
    {
      path: "/edit-module-and-menu/:module",
      name: "edit-module-and-menu",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Module And Menu", 
        parent: "module-and-menu-setup"
      }},
      component: lazyLoad('protected/ModuleMenu/EditModuleAndMenu')
    },
    {
      path: "/profile",
      name: "profile",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Profile' },
      component: lazyLoad('protected/Profile')
    },
    {
      path: "/profile-signature/:user_id",
      name: "profile-signature",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Profile' },
      component: lazyLoad('protected/ProfileSignature')
    },
    {
      path: "/profile-availability/:user_id",
      name: "profile-availability",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Profile' },
      component: lazyLoad('protected/ProfileAvailability')
    },
    {
      path: "/profile-documents/:user_id",
      name: "profile-documents",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Profile' },
      component: lazyLoad('protected/ProfileDocuments')
    },
    // 404 Not Found
    // {
    //   path: "/404",
    //   name: "404",
    //   meta: { layout: "ProtectedVue",middleware: "login"},
    //   component: () => import("@/components/404.vue")
    // },
    {  
      path: "/403",
      name: "403",
      meta: { layout: "ProtectedVue",middleware: "login", breadcrumb: 'Not Authorised'},
      component: lazyLoad('403')
    },

    /* Organization */
    {
      path: "/organization",
      name: "organizations",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Organization List", breadcrumb: 'Organization List' },
      component: lazyLoad("protected/organization/List-organization")
    },
    {
      path: "/organizations/add-organization",
      name: "add-organization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Organization List",
        breadcrumb: {
          label: 'Add Organization',
          parent: 'organizations'
        }  },
      component: lazyLoad("protected/organization/Add-organization")
    },
    {
      path: "/organizations/edit-organization/:orgId",
      name: "edit-organization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Organization List",
      breadcrumb: {
        label: 'Edit Organization',
        parent: 'organizations'
      }},
      component: lazyLoad("protected/organization/Edit-organization")
    },
    {
      path: "/organizations/delete-organization",
      name: "delete-organization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Organization List",
      breadcrumb: {
        label: 'Delete Organization',
        parent: 'organizations'
      }},
      component: lazyLoad("protected/organization/Delete-organization")
    },
    
    /* Agency */
    {
      path: "/agency",
      name: "agency",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Agency", breadcrumb: 'Agency List' },
      component: lazyLoad('protected/organization/Agency/Agency')
    },
    {
      path: "/add-agency",
      name: "add-agency",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Agency", breadcrumb: {
        label: "Add New Agency", 
        parent: "agency"
      } },
      component: lazyLoad("protected/organization/Agency/Add-agency")
    },
    {
      path: "/edit-agency/:agency_id",
      name: "edit-agency",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Agency", breadcrumb: {
        label: "Edit Agency", 
        parent: "agency"
      }},
      component: lazyLoad("protected/organization/Agency/Edit-agency")
    },
    {      
      path: "/delete-agency",
      name: "delete-agency",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Agency", breadcrumb: {
        label: "Delete Agency", 
        parent: "agency"
      }},
      component: lazyLoad("protected/organization/Agency/Delete-agency")
    },

    /* Payers */
    {
      path: "/payers",
      name: "payers",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Funders",group:"payer", breadcrumb: 'Funders' },
      component: lazyLoad("protected/organization/Payer/Payer")
    },
    {
      path: "/add-payer",
      name: "add-payer",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Funders",group:"payer", breadcrumb: {
        label: "Add New Funder", 
        parent: "payers"
      } },
      component: lazyLoad("protected/organization/Payer/Add-payer")
    },
    {
      path: "/edit-payer/:payer_id",
      name: "edit-payer",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Funders",group:"payer", breadcrumb: {
        label: "Edit Funder", 
        parent: "payers"
      }},
      component: lazyLoad("protected/organization/Payer/Edit-payer")
    },
    {
      path: "/delete-payer",
      name: "delete-payer",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Funders",group:"payer", breadcrumb: {
        label: "Delete Funders", 
        parent: "payers"
      }},
      component: lazyLoad("protected/organization/Payer/Delete-payer")
    },
    {
      path: "/payer-prompt-code/:payer_id",
      name: "payer-prompt-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payers-list",group:"payer-prompt-code", breadcrumb: {
        label: "Funder Prompt Code", 
        parent: "payer-plans"
      }},
      component: lazyLoad("protected/organization/Payer/payer-prompt-code")
    },

    /* Payer Cases */
    {
      path: "/payer-cases/:payer_id",
      name: "payer-cases",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-case-list",group:"payer-cases", breadcrumb: {
        label: "Funder Contact", 
        parent: "payers"
      } },
      component: () => import("@/components/protected/organization/PayerCase/payer-case.vue")
    },
    {
      path: "/add-payer-case/:payer_id",
      name: "add-payer-case",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-case-add",group:"payer-cases", breadcrumb: {
        label: "Add New Funder Contact", 
        parent: "payers"
      } },
      component: () => import("@/components/protected/organization/PayerCase/add-payer-case.vue")
    },
    {
      path: "/edit-payer-case/:payer_cases_id",
      name: "edit-payer-case",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-case-edit",group:"payer-cases", breadcrumb: {
        label: "Edit Funder Contact", 
        parent: "payers"
      }},
      component: () => import("@/components/protected/organization/PayerCase/edit-payer-case.vue")
    },

    /* Package */
    {
      path: "/package",
      name: "package",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"package-list" },
      component: lazyLoad("protected/package/package-list")
    },
    {
      path: "/add-package",
      name: "add-package",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"package-add" },
      component: lazyLoad("protected/package/package-add")
    },
    {
      path: "/edit-package/:package_id",
      name: "edit-package",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"package-edit"},
      component: lazyLoad("protected/package/package-edit")
    },

    /* Service */
    {
      path: "/service",
      name: "service",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service", breadcrumb: 'Services' },
      component: lazyLoad("protected/Service/Service")
    },
    {
      path: "/add-service",
      name: "add-service",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service", breadcrumb: {
        label: "Add New Service", 
        parent: "service"
      } },
      component: lazyLoad("protected/Service/Add-service")
    },
    {
      path: "/edit-services/:service_id",
      name: "edit-service",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service", breadcrumb: {
        label: "Edit Service", 
        parent: "service"
      }},
      component: lazyLoad("protected/Service/Edit-service")
    },
    {
      path: "/delete-service",
      name: "delete-service",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service", breadcrumb: {
        label: "Delete Service", 
        parent: "service"
      }},
      component: lazyLoad("protected/Service/Delete-service")
    },
    {
      path: "/import-service",
      name: "import-service",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service", breadcrumb: {
        label: "Import Service", 
        parent: "service"
      }},
      component: lazyLoad("protected/Service/Import-service")
    },

    /* Service Line */
    {
      path: "/service-line",
      name: "service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service Line", breadcrumb: 'Service Lines' },
      component: lazyLoad("protected/ServiceLine/Service-line")
    },
    {
      path: "/add-service-line",
      name: "add-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service Line", breadcrumb: {
        label: "Add New Service Line", 
        parent: "service-line"
      } },
      component: lazyLoad("protected/ServiceLine/Add-service-line")
    },
    {
      path: "/edit-services-line/:serviceline_id",
      name: "edit-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service Line", breadcrumb: {
        label: "Edit Service Line", 
        parent: "service-line"
      }},
      component: lazyLoad("protected/ServiceLine/Edit-service-line")
    },
    {
      path: "/delete-service-line",
      name: "delete-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service Line", breadcrumb: {
        label: "Delete Service Lines", 
        parent: "service-line"
      } },
      component: lazyLoad("protected/ServiceLine/Delete-service-line")
    },
    {
      path: "/import-service-line",
      name: "import-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Service Line", breadcrumb: {
        label: "Import Service Line", 
        parent: "service-line"
      }},
      component: lazyLoad("protected/ServiceLine/Import-service-line")
    },
    // File Management
    {
      path: "/file-management",
      name: "file-management",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: 'File-Management' },
      component: lazyLoad("protected/FileManagement/file-management")
    },
    // {
    //   path: "/folders-list",
    //   name: "folders-list",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"folder-list", breadcrumb: 'Folders List' },
    //   component: lazyLoad("protected/FileManagement/folders-list")
    // },
    // {
    //   path: "/create-folder",
    //   name: "create-folder",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Add New Folder", 
    //     parent: "file-management"
    //   } },
    //   component: lazyLoad("protected/FileManagement/create-folder")
    // },
    // {
    //   path: "/upload-file",
    //   name: "upload-file",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Upload File", 
    //     parent: "file-management"
    //   } },
    //   component: lazyLoad("protected/FileManagement/upload-file")
    // },
    // {
    //   path: "/update-folder/:folder_id",
    //   name: "update-folder",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Update Folder", 
    //     parent: "file-management"
    //   }},
    //   component: lazyLoad("protected/FileManagement/update-folder")
    // },
    // {
    //   path: "/update-file/:file_id",
    //   name: "update-file",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"folder-list", breadcrumb: {
    //     label: "Update File", 
    //     parent: "file-management"
    //   }},
    //   component: lazyLoad("protected/FileManagement/update-file")
    // },
    {
      path: "/view-folder-contents/:folder_id",
      name: "view-folder-contents",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Contents", 
        parent: "file-management"
      }},
      component: lazyLoad("protected/FileManagement/folder-contents")
    },
    {
      path: "/deleted-folders",
      name: "deleted-folders",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Deleted Folders", 
        parent: "file-management"
      } },
      component: lazyLoad("protected/FileManagement/deleted-folders")
    },
    {
      path: "/deleted-files",
      name: "deleted-files",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Deleted Files", 
        parent: "file-management"
      } },
      component: lazyLoad("protected/FileManagement/deleted-files")
    },
    
    {
      path: "/shared-folders",
      name: "shared-folders",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Shared-Folders", 
        parent: "file-management"
      } },
      component: lazyLoad("protected/FileManagement/shared-folders-list")
    },
    {
      path: "/share-folder/:folder_id",
      name: "share-folder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Share Folder", 
        parent: "file-management"
      } },
      component: lazyLoad("protected/FileManagement/share-folder")
    },
    {
      path: "/share-file/:file_id",
      name: "share-file",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Share File", 
        parent: "file-management"
      } },
      component: lazyLoad("protected/FileManagement/share-file")
    },  
    {
      path: "/shared-files",
      name: "shared-files",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Shared Files", 
        parent: "file-management"
      } },
      component: lazyLoad("protected/FileManagement/shared-files")
    },


    /* Folder */
    {
      path: "/folders",
      name: "folders",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: 'Folders' },
      component: lazyLoad("protected/folder/folder-list")
    },
    {
      path: "/add-folder",
      name: "add-folder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Add New Folder", 
        parent: "folders"
      } },
      component: lazyLoad("protected/folder/folder-add")
    },
    {
      path: "/edit-folder/:folder_id",
      name: "edit-folder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Edit Folder", 
        parent: "folders"
      }},
      component: lazyLoad("protected/folder/folder-edit")
    },
    {
      path: "/view-folder/:folder_id",
      name: "view-folder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Files", 
        parent: "folders"
      }},
      component: lazyLoad("protected/file/file-list")
    },
    {
      path: "/delete-folder",
      name: "delete-folder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Deleted Folders", 
        parent: "folders"
      } },
      component: lazyLoad("protected/folder/folder-delete")
    },

    /* File */
    {
      path: "/files",
      name: "files",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: 'Files' },
      component: lazyLoad("protected/file/file-list")
    },
    {
      path: "/add-file/:folder_id?",
      name: "add-file",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Add New File", 
        parent: "files"
      } },
      component: lazyLoad("protected/file/file-add")
    },
    {
      path: "/edit-file/:file_id",
      name: "edit-file",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Edit File", 
        parent: "files"
      }},
      component: lazyLoad("protected/file/file-edit")
    },
    {
      path: "/delete-file",
      name: "delete-file",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"File Management", breadcrumb: {
        label: "Deleted Files", 
        parent: "files"
      } },
      component: lazyLoad("protected/file/file-delete")
    },

    /* Goal */
    {
      path: "/add-goal",
      name: "add-goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"goals-add", breadcrumb: {
        label: "Add Target Playlists", 
        parent: "goal"
      } },
      component: lazyLoad("protected/organization/Goal/Add-goal")
    },
    {
      path: "/goal",
      name: "goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"goals-list", breadcrumb: 'Target Playlists' },
      component: lazyLoad("protected/organization/Goal/List-goal")
    },    
    {
      path: "/edit-goal/:goal_id",
      name: "edit-goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"goals-edit", breadcrumb: {
        label: "Edit Target Playlists", 
        parent: "goal"
      }},
      component: lazyLoad("protected/organization/Goal/Edit-goal")
    },
    {
      path: "/delete-goal",
      name: "delete-goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"goals-list", breadcrumb: {
        label: "Delete Program Categories", 
        parent: "goal"
      } },
      component: lazyLoad("protected/organization/Goal/Delete-goal")
    },


    /* Student */
    {
      path: "/student",
      name: "student",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: 'Students' },
      component: lazyLoad("protected/student/student-list")
    },
    {
      path: "/add-student",
      name: "add-student",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Add Student", 
        parent: "student"
      } },
      component: lazyLoad("protected/student/student-add")
    },
    {
      path: "/edit-student/:student_id",
      name: "edit-student",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Student Profile", 
        parent: "student"
      }},
      component: lazyLoad("protected/student/student-edit")
    },
    {      
      path: "/delete-student",
      name: "delete-student",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Delete Student", 
        parent: "student"
      }},
      component: lazyLoad("protected/student/Delete-student")
    },

    /* Staff (User) */
    {
      path: "/staff",
      name: "users",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"Staff List", breadcrumb: 'Staff' },
      component: lazyLoad("protected/User/User")
    },
    {
      path: "/add-staff",
      name: "add-user",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"Staff List", breadcrumb: {
        label: "Add New Staff", 
        parent: "users"
      } },
      component: lazyLoad("protected/User/Add-user")
    },
    {
      path: "/user-document-settings",
      name: "document-settings",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Document Label Settings", 
        parent: "users"
      } },
      component: lazyLoad("protected/User/UserUploadFileAdmin")
    },
    {
      path: "/add-user-document-settings",
      name: "add-document-settings",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Add Edit Document Label Settings", 
        parent: "users"
      } },
      component: lazyLoad("protected/User/AddEditUserUploadFileAdmin")
    },
    {
      path: "/edit-staff/:user_id",
      name: "edit-user",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/Edit-user")
    },
    {
      path: "/staff-payout/:user_id",
      name: "user-payout",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-payout")
    },
    {      
      path: "/delete-staff",
      name: "delete-user",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Delete Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-delete")
    },
    {      
      path: "/daily-salary",
      name: "daily-salary",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Daily Salary", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/salary/daily-salary")
    },
    {      
      path: "/staff-daily-salary/:user_id",
      name: "staff-daily-salary",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Staff Daily Salary", 
        parent: "daily-salary"
      }},
      component: lazyLoad("protected/User/salary/staff-daily-salary")
    },
    {      
      path: "/due-payout",
      name: "due-payout",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Due Payout", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/salary/due-payout")
    },
    {      
      path: "/staff-due-payout/:user_id",
      name: "staff-due-payout",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Staff Due Payout", 
        parent: "due-payout"
      }},
      component: lazyLoad("protected/User/salary/staff-due-payout")
    },
    {      
      path: "/staff-salary",
      name: "staff-salary",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Staff Salary", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/salary/staff-salary")
    },
    {      
      path: "/staff-documents/:user_id",
      name: "staff-documents",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Staff Documents", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-documents")
    },
    {      
      path: "/staff-students/:user_id",
      name: "staff-students",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Staff Students", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-students/user-students")
    },
    {      
      path: "/staff-schools/:user_id",
      name: "staff-schools",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Staff Schools", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-schools")
    },

    /* Staff Service Line */
    {
      path: "/staff-service-line/:user_id",
      name: "staff-service-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/UserServiceLine/User-Serviceline")
    },
    {
      path: "/staff/add-service-line/:user_id",
      name: "staff-service-add",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/UserServiceLine/Add-user-Serviceline")
    },
    {
      path: "/staff/edit-service-line/:service_line_id",
      name: "staff-service-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/UserServiceLine/Edit-user-Serviceline")
    },
    {
      path: "/staff/signature/:user_id",
      name: "staff-signature",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-signature")
    },
    {
      path: "/staff/availability/:user_id",
      name: "staff-availability",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Staff", 
        parent: "users"
      }},
      component: lazyLoad("protected/User/user-availability")
    },
    {
      path: "/update-signature/:id",
      name: "sesion-guest-signature",
      meta: { layout: "ProtectedVue"},
      component: lazyLoad("protected/User/guest-signature")
    },

    /* Billing Code */
    {
      path: "/billingcodes",
      name: "billingcodes",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list" },
      component: lazyLoad("protected/organization/BillingCode/Billingcode")
    },
    {
      path: "/add-billingcode",
      name: "add-billingcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-add" },
      component: lazyLoad("protected/organization/BillingCode/Billingcode-add")
    },
    {
      path: "/edit-billingcode/:billingcode_id",
      name: "edit-billingcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-edit" },
      component: lazyLoad("protected/organization/BillingCode/Billingcode-edit")
    },
    {
      path: "/delete-billingcode",
      name: "delete-billingcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list" },
      component: lazyLoad("protected/organization/BillingCode/Delete-Billingcode")
    },
    {
      path: "/import-billingcode",
      name: "import-billingcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list"},
      component: lazyLoad("protected/organization/BillingCode/Import-Billingcode")
    },

    /* Diagnosis */
    {
      path: "/diagnosis",
      name: "diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"diagnosis-list" },
      component: lazyLoad("protected/organization/Diagnosis/Diagnosis")
    },
    {
      path: "/add-diagnosis",
      name: "add-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"diagnosis-add" },
      component: lazyLoad("protected/organization/Diagnosis/Diagnosis-add")
    },  
    {
      path: "/delete-diagnosis",
      name: "delete-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"diagnosis-delete" },
      component: lazyLoad("protected/organization/Diagnosis/Diagnosis-delete")
    },
    {
      path: "/edit-diagnosis/:diagnosis_id",
      name: "edit-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"diagnosis-edit" },
      component: lazyLoad("protected/organization/Diagnosis/Diagnosis-edit")
    },
    {
      path: "/import-diagnosis",
      name: "import-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"diagnosis-list"},
      component: lazyLoad("protected/organization/Diagnosis/Import-Diagnosis")
    },
    /* Program */
    {
      path: "/program",
      name: "programs",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
      component: lazyLoad("protected/organization/Program/program-list")
    },
    {
      path: "/add-program",
      name: "add-program",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-add" },
      component: lazyLoad("protected/organization/Program/program-add")
    },
    {
      path: "/edit-program/:program_id",
      name: "edit-program",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-edit"},
      component: lazyLoad("protected/organization/Program/program-edit")
    }, 
    {
      path: "/delete-program",
      name: "delete-program",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
      component: lazyLoad("protected/organization/Program/program-delete")
    },
    {
      path: "/import-program",
      name: "import-program",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list"},
      component: lazyLoad("protected/organization/Program/Import-Program")
    },
    /* Assessment Category */
    {
      path: "/assessment/category/:parent_id?",
      name: "add-assessment-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-add" },
      component: lazyLoad("protected/organization/AssessmentCategory/add-category")
    },
    {
      path: "/assessment/categorylist",
      name: "assessment-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-list" },
      component: lazyLoad("protected/organization/AssessmentCategory/category")
    },
    {
      path: "/assessment/child-category/:parent_id",
      name: "child-assessment-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-add" },
      component: lazyLoad("protected/organization/AssessmentCategory/child-category")
    },
    {
      path: "/assessment/category/edit-category/:cat_id",
      name: "edit-assessment-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-edit"},
      component: lazyLoad("protected/organization/AssessmentCategory/edit-category")
    },  
    {
      path: "/assessment/category/delete",
      name: "delete-assessment-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-list" },
      component: lazyLoad("protected/organization/AssessmentCategory/category-delete")
    },

    /* Assessment */
    {
      path: "/add-assessment/",
      name: "add-assessment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-add" },
      component: lazyLoad("protected/organization/Assessment/add-assessment")
    },
    {
      path: "/assessment/",
      name: "assessment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-list" },
      component: lazyLoad("protected/organization/Assessment/assessment")
    },
    {
      path: "/edit-assessment/:assessment_id",
      name: "edit-assessment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-edit"},
      component: lazyLoad("protected/organization/Assessment/edit-assessment")
    },
    {
      path: "/delete-assessment",
      name: "delete-assessment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"assessments-category-list" },
      component: lazyLoad("protected/organization/Assessment/delete-assessment")
    }, 

    /* Payer Service Line */
    {
      path: "/payer-service-line/:payer_id",
      name: "add-payer-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-serviceline-add",group:"payer-service-line" },
      component: lazyLoad("protected/organization/PayerServiceLine/Add-payer-serviceline")
    },
    {
      path: "/payers/service-line/:payer_id",
      name: "payer-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-serviceline-list",group:"payer-service-line" },
      component: lazyLoad("protected/organization/PayerServiceLine/Payer-serviceline")
    },
    {
      path: "/payers-service-line/:service_line_id",
      name: "edit-payer-service-line",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-serviceline-edit",group:"payer-service-line"},
      component: lazyLoad("protected/organization/PayerServiceLine/Edit-payer-serviceline")
    },

    /* Payer Billing Code */
    {
      path: "/payer-billing-code/:payer_id",
      name: "add-payer-billing-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-billingcode-add",group:"payer-billing-code", breadcrumb: {
        label: "Add New Funder Billing Code", 
        parent: "payer-plans"
      } },
      component: lazyLoad("protected/organization/PayerBillingCode/Add-payer-billingcode")
    },
    {
      path: "/payers/billing-code/:payer_id",
      name: "payer-billing-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-billingcode-list",group:"payer-billing-code", breadcrumb: {
        label: "Funder Billing Code", 
        parent: "payer-plans"
      } },
      component: lazyLoad("protected/organization/PayerBillingCode/Payer-billingcode")
    },
    {
      path: "/payers-billing-code/:billing_code_id",
      name: "edit-payer-billing-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payer-billingcode-edit",group:"payer-billing-code", breadcrumb: {
        label: "Edit Billing Code", 
        parent: "payer-plans"
      }},
      component: lazyLoad("protected/organization/PayerBillingCode/Edit-payer-billingcode")
    },

    /* Certificate */
    {
      path: "/certification/",
      name: "add-certification",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Certifications", breadcrumb: {
        label: "Add New Certification", 
        parent: "certifications"
      } },
      component: lazyLoad("protected/organization/Certification/Add-certification")
    },
    {
      path: "/edit-certification/:cid",
      name: "edit-certification",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Certifications", breadcrumb: {
        label: "Edit Certification", 
        parent: "certifications"
      } },
      component: lazyLoad("protected/organization/Certification/Edit-certification")
    },
    {
      path: "/certifications/",
      name: "certifications",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Certifications", breadcrumb: 'Certifications'},
      component: lazyLoad("protected/organization/Certification/List-certification")
    },    
    {
      path: "/delete-certifications",
      name: "delete-certifications",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Certifications", breadcrumb: {
        label: "Delete Certification", 
        parent: "certifications"
      }},
      component: lazyLoad("protected/organization/Certification/Delete-certification")
    },
    {
      path: "/import-certifications",
      name: "import-certifications",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Certifications", breadcrumb: {
        label: "Import Certification", 
        parent: "certifications"
      }},
      component: lazyLoad("protected/organization/Certification/Import-certification")
    },

    /* Parents (User) */
    {
      path: "/parents",
      name: "parents",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: 'Parents' },
      component: lazyLoad("protected/Parents/Parents")
    },
    {
      path: "/add-parent",
      name: "add-parent",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Add Parents", 
        parent: "parents"
      } },
      component: lazyLoad("protected/Parents/Add-parents")
    },
    {
      path: "/edit-parent/:user_id",
      name: "edit-parent",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Edit Parent", 
        parent: "parents"
      }},
      component: lazyLoad("protected/Parents/Edit-parents")
    },
    {
      path: "/parent/signature/:user_id",
      name: "parent-signature",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",breadcrumb: {
        label: "Edit Signature", 
        parent: "parents"
      }},
      component: lazyLoad("protected/Parents/parents-signature")
    },
    {      
      path: "/delete-parent",
      name: "delete-parent",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Delete Parent", 
        parent: "parents"
      }},
      component: lazyLoad("protected/Parents/parents-delete")
    },

    /* Parent Service Line */
    {
      path: "/parent-service-line/:user_id",
      name: "parent-service-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile"},
      component: lazyLoad("protected/ParentsServiceLine/Parents-Serviceline")
    },
    {
      path: "/parent/add-service-line/:user_id",
      name: "parent-service-add",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile"},
      component: lazyLoad("protected/ParentsServiceLine/Add-parents-Serviceline")
    },
    {
      path: "/parent/edit-service-line/:service_line_id",
      name: "parent-service-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile"},
      component: lazyLoad("protected/ParentsServiceLine/Edit-parents-Serviceline")
    },

    /* Stage Status Master */
    {
      path: "/stage-status-master",
      name: "stage-status-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-master-list", breadcrumb: 'Stage Status Master' },
      component: lazyLoad("protected/organization/StageStatusMaster/Stage-status-master")      
    },
    {
      path: "/add-stage-status-master",
      name: "add-stage-status-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-master-add", breadcrumb: {
        label: "Add New Stage Status Master", 
        parent: "stage-status-master"
      } },
      component: lazyLoad("protected/organization/StageStatusMaster/Add-stage-status-master")
    },
    {
      path: "/edit-stage-status-master/:stage_status_master_id",
      name: "edit-stage-status-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-master-edit", breadcrumb: {
        label: "Edit Stage Status Master", 
        parent: "stage-status-master"
      }},
      component: lazyLoad("protected/organization/StageStatusMaster/Edit-stage-status-master")
    }, 
    {
      path: "/delete-stage-status-master",
      name: "delete-stage-status-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-master-list", breadcrumb: {
        label: "Delete Stage Status Master", 
        parent: "stage-status-master"
      }},
      component: lazyLoad("protected/organization/StageStatusMaster/Delete-stage-status-master")
    }, 

    /* Check List Master */
    {
      path: "/checklist-master",
      name: "checklist-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-masters-list" },
      component: lazyLoad("protected/organization/ChecklistMaster/Checklist-master")      
    },
    {
      path: "/add-checklist-master",
      name: "add-checklist-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-masters-add" },
      component: lazyLoad("protected/organization/ChecklistMaster/Add-checklist-master")
    },
    {
      path: "/edit-checklist-master/:checklist_master_id",
      name: "edit-checklist-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-masters-edit"},
      component: lazyLoad("protected/organization/ChecklistMaster/Edit-checklist-master")
    }, 
    {
      path: "/delete-checklist-master",
      name: "delete-checklist-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-masters-list"},
      component: lazyLoad("protected/organization/ChecklistMaster/Delete-checklist-master")
    }, 

    /* Dynamic Field */
    {
      path: "/dynamic-field",
      name: "dynamic-field",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"dynamic-fields-list" },
      component: lazyLoad("protected/organization/DynamicField/Dynamic-field")      
    },
    {
      path: "/add-dynamic-field",
      name: "add-dynamic-field",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"dynamic-fields-add" },
      component: lazyLoad("protected/organization/DynamicField/Add-dynamic-field")
    },
    {
      path: "/edit-dynamic-field/:dynamic_field_id",
      name: "edit-dynamic-field",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"dynamic-fields-edit"},
      component: lazyLoad("protected/organization/DynamicField/Edit-dynamic-field")
    }, 
    {
      path: "/delete-dynamic-field",
      name: "delete-dynamic-field",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"dynamic-fields-list"},
      component: lazyLoad("protected/organization/DynamicField/Delete-dynamic-field")
    }, 
    /* Role */
    {
      path: "/roles",
      name: "roles",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-list", breadcrumb: 'Roles'},
      component: lazyLoad("protected/organization/Role/role")      
    },
    {
      path: "/permissions",
      name: "permissions",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-list", breadcrumb: 'Permissions'},
      component: lazyLoad("protected/organization/Permissions/PermissionsList")      
    },
    {
      path: "/add-role",
      name: "add-role",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-add", breadcrumb: {
        label: "Add Role", 
        parent: "roles"
      }},
      component: lazyLoad("protected/organization/Role/add-role")
    },
    {
      path: "/edit-role/:role_id",
      name: "edit-role",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-edit", breadcrumb: {
        label: "Edit Role", 
        parent: "roles"
      }},
      component: lazyLoad("protected/organization/Role/edit-role")
    },
    /* User Group */
    {
      path: "/user-group",
      name: "user-group",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-list", breadcrumb: 'Staff Titles'},
      component: lazyLoad("protected/organization/UserGroup/group")      
    },
    {
      path: "/add-user-group",
      name: "add-user-group",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-add", breadcrumb: {
        label: "Add Staff Title", 
        parent: "user-group"
      }},
      component: lazyLoad("protected/organization/UserGroup/add-group")
    },
    {
      path: "/edit-user-group/:group_id",
      name: "edit-user-group",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"role-edit", breadcrumb: {
        label: "Edit Staff Title", 
        parent: "user-group"
      }},
      component: lazyLoad("protected/organization/UserGroup/edit-group")
    },

    /* Check List Form Templates */
    {
      path: "/checklist-form-template",
      name: "checklist-form-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-form-template-list" },
      component: lazyLoad("protected/organization/ChecklistFormTemplates/Checklist-form-templates")      
    },
    {
      path: "/add-checklist-form-template",
      name: "add-checklist-form-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-form-template-add" },
      component: lazyLoad("protected/organization/ChecklistFormTemplates/Add-checklist-form-templates")
    },
    {
      path: "/edit-checklist-form-template/:checklist_form_template_id",
      name: "edit-checklist-form-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-form-template-edit"},
      component: lazyLoad("protected/organization/ChecklistFormTemplates/Edit-checklist-form-templates")
    }, 
    {
      path: "/delete-checklist-form-template",
      name: "delete-checklist-form-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"checklist-form-template-list"},
      component: lazyLoad("protected/organization/ChecklistFormTemplates/Delete-checklist-form-templates")
    }, 

    /* Stage Status */
    {
      path: "/stage-status",
      name: "stage-status",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-list" },
      component: lazyLoad("protected/organization/StageStatus/Stage-status")      
    },
    {
      path: "/add-stage-status",
      name: "add-stage-status",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-add" },
      component: lazyLoad("protected/organization/StageStatus/Add-stage-status")
    },
    {
      path: "/edit-stage-status/:stage_status_id",
      name: "edit-stage-status",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-edit"},
      component: lazyLoad("protected/organization/StageStatus/Edit-stage-status")
    }, 
    {
      path: "/delete-stage-status",
      name: "delete-stage-status",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"stage-status-list"},
      component: lazyLoad("protected/organization/StageStatus/Delete-stage-status")
    },

    {
      path: "/student-billing-calendar/:student_id",
      name: "student-billing-calendar",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Billing Calendar", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_billing_calendar/billing-calendar")
    },

    /* Student Contact*/
    {
      path: "/student-contacts/:student_id",
      name: "student-contacts",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-contact", breadcrumb: {
        label: "Contacts", 
        parent: "student"
      } },
      component: lazyLoad("protected/students/student_contacts/contacts-list")
    },
    {
      path: "/student/add-contact/:student_id",
      name: "add-student-contact",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-contact", breadcrumb: {
        label: "Add Student Contact", 
        parent: "student-contacts"
      } },
      component: lazyLoad("protected/students/student_contacts/contact-add")
    },
    {
      path: "/student/edit-contact/:contact_id/:student_id",
      name: "edit-student-contact",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-contact", breadcrumb: {
        label: "Edit Student", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_contacts/contact-edit")
    },
    {      
      path: "/student/delete-contact",
      name: "delete-student-contact",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-contact", breadcrumb: {
        label: "Edit Student", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_contacts/contact-delete")
    },

    /* Student Assesment*/
    {
      path: "/student-assessments/:student_id",
      name: "student-assessments",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-assessments", breadcrumb: {
        label: "Assessments", 
        parent: "student"
      } },
      component: lazyLoad("protected/students/student_assessments/assessment-dashboard")
    },
    {
      path: "/student/student-skill-assessments/:catId/:student_id",
      name: "student-skill-assessments",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-assessments", breadcrumb: {
        label: "Skill Assessment", 
        parent: "student-assessments"
      } },
      component: lazyLoad("protected/students/student_assessments/assessment-skill")
    },
    {
      path: "/student/student-preference-assessments/:catId/:student_id",
      name: "student-preference-assessments",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-assessments", breadcrumb: {
        label: "Preference Assessment", 
        parent: "student-assessments"
      } },
      component: lazyLoad("protected/students/student_assessments/assessment-preference")
    },
    {
      path: "/student/student-health-assessments/:catId/:student_id",
      name: "student-health-assessments",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-assessments", breadcrumb: {
        label: "Health Assessment", 
        parent: "student-assessments"
      } },
      component: lazyLoad("protected/students/student_assessments/assessment-health")
    },
    {
      path: "/student/edit-assessment/:assessment_id",
      name: "edit-student-assessment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-assessments"},
      component: lazyLoad("protected/students/student_assessments/assessment-edit")
    },
    
    /* Student Diagnosis*/
    {
      path: "/student-diagnosis/:student_id",
      name: "student-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-diagnosis", breadcrumb: {
        label: "Diagnosis", 
        parent: "student"
      } },
      component: lazyLoad("protected/students/student_diagnosis/diagnosis")
    },
    {
      path: "/student/add-diagnosis/:student_id",
      name: "add-student-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-diagnosis", breadcrumb: {
        label: "Add Student Diagnosis", 
        parent: "student-diagnosis"
      } },
      component: lazyLoad("protected/students/student_diagnosis/diagnosis-add")
    },
    {
      path: "/student/edit-diagnosis/:diagnosis_id/:student_id",
      name: "edit-student-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-diagnosis", breadcrumb: {
        label: "Edit Student Diagnosis", 
        parent: "student-diagnosis"
      }},
      component: lazyLoad("protected/students/student_diagnosis/diagnosis-edit")
    },
    {      
      path: "/student/delete-diagnosis",
      name: "delete-student-diagnosis",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-diagnosis"},
      component: lazyLoad("protected/students/student_diagnosis/diagnosis-delete")
    },

    /* Student Services/Funders */
    {
      path: "/student/funder/:student_id",
      name: "student-funder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-funder", breadcrumb: {
        label: "Funders", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_funder/funder-list")
    },
    {
      path: "/student/add-funder/:student_id",
      name: "add-student-funder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-funder", breadcrumb: {
        label: "Add Student Funder", 
        parent: "student-funder"
      }},
      component: lazyLoad("protected/students/student_funder/funder-add")
    },
    {
      path: "/student/edit-funder/:funder_id/:student_id",
      name: "edit-student-funder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-funder", breadcrumb: {
        label: "Edit Student Funder", 
        parent: "student-funder"
      }},
      component: lazyLoad("protected/students/student_funder/funder-edit")
    },

    /* Student Authorization */
    {
      path: "/student/add-authorization/:student_id",
      name: "add-student-authorization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-authorization", breadcrumb: {
        label: "Add Authorization", 
        parent: "student-authorization"
      }},
      component: lazyLoad("protected/students/student_authorization/authorization-add")
    },
    {
      path: "/student/copy-authorization/:edit_id/:student_id",
      name: "copy-student-authorization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-authorization", breadcrumb: {
        label: "Copy Authorization", 
        parent: "student-authorization"
      }},
      component: lazyLoad("protected/students/student_authorization/authorization-copy")
    },
    {
      path: "/student/authorization/:student_id",
      name: "student-authorization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-authorization", breadcrumb: {
        label: "Authorization", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_authorization/authorization-list")
    },
    {
      path: "/student/edit-authorization/:edit_id/:student_id",
      name: "edit-student-authorization",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-authorization", breadcrumb: {
        label: "Edit Authorization", 
        parent: "student-authorization"
      }},
      component: lazyLoad("protected/students/student_authorization/authorization-edit")
    },

    /* Student Availability */
    {
      path: "/student/availability/:student_id",
      name: "student-availability",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Availability", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_availability")
    },

    /* Student File */
    {
      path: "/student/file/:student_id",
      name: "student-file",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"Student List", breadcrumb: {
        label: "Student Files", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_files/student_file")
    },
    /* Student Files */
    {
      path: "/student/file/:active_component/:student_id",
      name: "student-active-component",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Student Files", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_files/student_active_component")
    },

    {
      path: "/student/file/folder-contents/:folder_id/:student_id",
      name: "student-folder-contents",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"Student List", breadcrumb: {
        label: "Student Folder Contents", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_files/student_folder_contents")
    },

    // {
    //   path: "/student/file/share-file/:file_id/:student_id",
    //   name: "student-share-file",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
    //     label: "Student Share File", 
    //     parent: "student"
    //   }},
    //   component: lazyLoad("protected/students/student_files/student_share_file")
    // },

    // {
    //   path: "/student/file/share-folder/:folder_id/:student_id",
    //   name: "student-share-folder",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
    //     label: "Student Share Folder", 
    //     parent: "student"
    //   }},
    //   component: lazyLoad("protected/students/student_files/student_share_folder")
    // },

    // {
    //   path: "/student/file/update-folder/:folder_id/:student_id",
    //   name: "student-update-folder",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
    //     label: "Student Update Folder", 
    //     parent: "student"
    //   }},
    //   component: lazyLoad("protected/students/student_files/student_update_folder")
    // },

    // {
    //   path: "/student/file/update-file/:file_id/:student_id",
    //   name: "student-update-file",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
    //     label: "Student Update File", 
    //     parent: "student"
    //   }},
    //   component: lazyLoad("protected/students/student_files/student_update_file")
    // },

    /* Student staff */
    {
      path: "/student/staff/:student_id",
      name: "student-staff",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Staff Members", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_staff")
    },
    /* Student settings */
    {
      path: "/student/settings/:student_id",
      name: "student-settings",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List", breadcrumb: {
        label: "Settings", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_settings")
    },

    /* Student goal */
    {
      path: "/student/add-goal/:student_id",
      name: "add-student-goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-goal", breadcrumb: {
        label: "Add Target Playlist", 
        parent: "student-goals"
      }},
      component: lazyLoad("protected/students/student_goal/goal-add")
    },
    {
      path: "/student/goal/:student_id",
      name: "student-goals",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-goal", breadcrumb: {
        label: "Target Playlists", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_goal/goal-list")
    },
    {
      path: "/student/edit-goal/:goal_id/:student_id",
      name: "edit-student-goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-goal", breadcrumb: {
        label: "Edit Target Playlist", 
        parent: "student-goals"
      }},
      component: lazyLoad("protected/students/student_goal/goal-edit")
    },

    /* Student Promptcode */
    {
      path: "/student/add-promptcode/:student_id",
      name: "add-student-promptcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-promptcode", breadcrumb: {
        label: "Add Prompt Code", 
        parent: "student-promptcode"
      }},
      component: lazyLoad("protected/students/student_promptcode/promptcode-add")
    },
    {
      path: "/student/promptcode/:student_id",
      name: "student-promptcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student List",group:"student-promptcode", breadcrumb: {
        label: "Prompt Code", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_promptcode/promptcode-list")
    },
    {
      path: "/student/edit-promptcode/:promptcode_id/:student_id",
      name: "edit-student-promptcode",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Student Listt",group:"student-promptcode", breadcrumb: {
        label: "Edit Prompt Code", 
        parent: "student-promptcode"
      }},
      component: lazyLoad("protected/students/student_promptcode/promptcode-edit")
    },
    
    /* Payer Plans */
    {
      path: "/add-payer-plans/",
      name: "payer-plan-add",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payers-plan-add", breadcrumb: {
        label: "Add New Plan", 
        parent: "payer-plans"
      } },
      component: lazyLoad("protected/organization/PayerPlan/Add-plan")
    },
    {
      path: "/edit-payer-plan/:plan_id",
      name: "payer-plan-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payers-plan-edit", breadcrumb: {
        label: "Edit Plan", 
        parent: "payer-plans"
      } },
      component: lazyLoad("protected/organization/PayerPlan/Edit-plan")
    },
    {
      path: "/payer-plans/",
      name: "payer-plans",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payers-plan-list", breadcrumb: 'Funder Plans'},
      component: lazyLoad("protected/organization/PayerPlan/Plans")
    },    
    {
      path: "/delete-payer-plan",
      name: "payer-plans-delete",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"payers-plan-delete", breadcrumb: {
        label: "Delete Funder Plans", 
        parent: "payer-plans"
      }},
      component: lazyLoad("protected/organization/PayerPlan/Delete-plan")
    },
    
    /* calendar */
    {
      path: "/calendar",
      name: "calendar",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"organization-list"},
      component: lazyLoad("protected/calendar/calendar")
    },
    {
      path: "/global-calendar/:filter_date?",
      name: "global-calendar",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Calendar / List View", breadcrumb: 'Calendar'},
      component: lazyLoad("protected/calendar/GlobalCalendar")
    },
    {
      path: "/create-appointment",
      name: "create-appointment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-form-template-add"},
      component: lazyLoad("protected/calendar/createAppointment")
    },
    {
      path: "/edit-appointment/:id",
      name: "edit-appointment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-form-template-add"},
      component: lazyLoad("protected/calendar/createAppointment")
    },
    {
      path: "/appointment-list",
      name: "appointment-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Calendar / List View", breadcrumb: 'Appointments'},
      component: lazyLoad("protected/calendar/AppointmentList")
    },
    // Rethink-Import-Status
    {
      path: "/rethink-sessions-data",
      name: "rethink-sessions-data",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Rethink Session Data", breadcrumb: 'Rethink Session Data'},
      component: lazyLoad("protected/calendar/RethinkSessionData")
    },

    /* Group-Schedule */
    {
      path: "/billing-schedule",
      name: "billing-schedule",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"student-list", breadcrumb: 'Billing Schedule'},
      component: lazyLoad("protected/calendar/BillingScheduleList")
    },
    {
      path: "/group-schedule",
      name: "group-schedule",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"student-list", breadcrumb: 'Group Schedule'},
      component: lazyLoad("protected/calendar/GroupScheduleList")
    },

    {
      path: "/group-schedule-add",
      name: "group-schedule-add",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"student-list",breadcrumb: {
        label: "Add Group Schedule", 
        parent: "group-schedule"
      }},
      component: lazyLoad("protected/calendar/GroupScheduleAdd")
    },
    {
      path: "/group-schedule-edit/:schedule_id",
      name: "group-schedule-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"student-list",breadcrumb: {
        label: "Edit Group Schedule", 
        parent: "group-schedule"
      }},
      component: lazyLoad("protected/calendar/GroupScheduleEdit")
    },
    {
      path: "/group-schedule-assign-students/:schedule_id",
      name: "group-schedule-assign-students",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"student-list",breadcrumb: {
        label: "Assign Students", 
        parent: "group-schedule"
      }},
      component: lazyLoad("protected/calendar/GroupScheduleAssignStudents")
    },

    /*Goal Generator*/
    {
      path: "/goal-generator/excl-doc",
      name: "excel-document",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: 'Excl To Doc'},
      component: lazyLoad("protected/Goal/ExclDoc")
    },
    {
      path: "/goal-generator/doc-excl",
      name: "document-excel",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: 'Doc To Excl'},
      component: lazyLoad("protected/Goal/DocExcl")
    },
    {
      path: "/goal-generator/doc-excl-v2",
      name: "document-excel-v2",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: 'Doc To Excl V2'},
      component: lazyLoad("protected/Goal/DocExclV2")
    },
    {
      path: "/goal-generator/doc-excl/template-list",
      name: "document-conversion-template-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
        label: "Template List",
        parent: "document-excel"
      }},
      component: lazyLoad("protected/Goal/Conversion/ConversionTemplateList")
    },
    {
      path: "/goal-generator/doc-excl/template",
      name: "document-conversion-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
        label: "Template Add",
        parent: "document-conversion-template-list"
      }},
      component: lazyLoad("protected/Goal/Conversion/ConversionTemplateAdd")
    },
    {
      path: "/goal-generator/doc-excl/template/:u_id",
      name: "document-conversion-template-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
        label: "Template Edit",
        parent: "document-conversion-template-list"
      }},
      component: lazyLoad("protected/Goal/Conversion/ConversionTemplateEdit")
    },
    {
      path: "/goal-generator/edit-file-data/:file_id",
      name: "edit-file-data",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Generator", breadcrumb: {
        label: "Edit File Data",
        parent: "document-excel"
      }},
      component: lazyLoad("protected/Goal/EditClientFile")
    },

    /* Goal Attributes */
    {
      path: "/goal-generator/goal-activity-list",
      name: "goal-activity-list",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Goal Generator", breadcrumb: 'Goal Activity List' },
      component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeList")
    },

    {
      path: "/goal-generator/add-goal-activity",
      name: "add-goal-activity",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Goal Generator", 
          breadcrumb: {
            label: "Add Goal Activity",
            parent: "goal-activity-list"
          }
      },
      component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeAdd")
    },

    {
      path: "/goal-generator/edit-goal-activity/:actId",
      name: "edit-goal-activity",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Goal Generator", 
        breadcrumb: {
          label: "Edit Goal Activity",
          parent: "goal-activity-list"
        }
      },
      component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeEdit")
    },

    {
      path: "/goal-generator/goal-activity-list-deleted",
      name: "goal-activity-list-deleted",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Goal Generator", 
        breadcrumb: {
          label: "Goal Activity List Deleted",
          parent: "goal-activity-list"
        } }
      ,
      component: lazyLoad("protected/Goal/GoalAttributes/GoalAttributeListDeleted")
    },

     /* message */
    {
      path: "/inbox",
      name: "inbox",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Message", breadcrumb: 'Inbox'},
      component: lazyLoad("protected/Message/Inbox")
    },
    {
      path: "/send",
      name: "send",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Message", breadcrumb: 'Send'},
      component: lazyLoad("protected/Message/Send")
    },
    {
      path: "/compose",
      name: "compose",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Message", breadcrumb: 'Compose'},
      component: lazyLoad("protected/Message/Compose")
    },
    {
      path: "/trash",
      name: "trash",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Message", breadcrumb: 'Trash'},
      component: lazyLoad("protected/Message/Trash")
    },
    {
      path: "/message/:id",
      name: "message-details",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Message", breadcrumb: 'Message'},
      component: lazyLoad("protected/Message/MessageDetails")
    },

    /* Location */
    {
      path: "/add-location/",
      name: "add-location",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Add New Location", 
        parent: "locations"
      } },
      component: lazyLoad("protected/organization/Location/Add-location")
    },
    {
      path: "/edit-location/:location_id",
      name: "edit-location",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
        label: "Edit Location", 
        parent: "locations"
      } },
      component: lazyLoad("protected/organization/Location/Edit-location")
    },
    {
      path: "/locations/",
      name: "locations",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Locations'},
      component: lazyLoad("protected/organization/Location/List-location")
    },    
    {
      path: "/delete-locations",
      name: "delete-locations",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: {
        label: "Delete Locations", 
        parent: "locations"
      }},
      component: lazyLoad("protected/organization/Location/Delete-location")
    },

    /* Session Type */
    {
      path: "/session-type",
      name: "session-type",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"session-template-type-list"},
      component: lazyLoad("protected/SessionTemplates/Types/Type")
    },
    {
      path: "/edit-session-type/:session_type_id",
      name: "edit-session-type",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"session-template-type-edit"},
      component: lazyLoad("protected/SessionTemplates/Types/Edit-type")
    },
    {
      path: "/add-session-type/",
      name: "add-session-type",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-template-type-add" },
      component: lazyLoad("protected/SessionTemplates/Types/Add-type")
    },
    {
      path: "/delete-session-type",
      name: "delete-session-type",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-template-type-delete"},
      component: lazyLoad("protected/SessionTemplates/Types/Delete-type")
    },

    /* Session Master */
    {
      path: "/session-master",
      name: "session-master",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"session-template-master-list"},
      component: lazyLoad("protected/SessionTemplates/Master/Master")
    },
    {
      path: "/edit-session-master/:session_master_id",
      name: "edit-session-master",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"session-template-master-edit"},
      component: lazyLoad("protected/SessionTemplates/Master/Edit-master")
    },
    {
      path: "/add-session-master/",
      name: "add-session-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-template-master-add" },
      component: lazyLoad("protected/SessionTemplates/Master/Add-master")
    },
    {
      path: "/delete-session-master",
      name: "delete-session-master",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-template-master-delete"},
      component: lazyLoad("protected/SessionTemplates/Master/Delete-master")
    },

    /* Session Form */
    {
      path: "/session-form",
      name: "session-form",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"session-form-template-list"},
      component: lazyLoad("protected/SessionTemplates/Form/Form")
    },
    {
      path: "/edit-session-form/:session_form_id",
      name: "edit-session-form",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"session-form-template-edit"},
      component: lazyLoad("protected/SessionTemplates/Form/Edit-form")
    },
    {
      path: "/add-session-form/",
      name: "add-session-form",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-form-template-add" },
      component: lazyLoad("protected/SessionTemplates/Form/Add-form")
    },
    {
      path: "/delete-session-form",
      name: "delete-session-form",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"session-form-template-delete"},
      component: lazyLoad("protected/SessionTemplates/Form/Delete-form")
    },

    /* Reminder */
    {
      path: "/add-reminder/",
      name: "add-reminder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Add Reminder", 
        parent: "reminders"
      } },
      component: lazyLoad("protected/organization/Reminder/Add-reminder")
    },
    {
      path: "/edit-reminder/:reminder_id",
      name: "edit-reminder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
        label: "Edit Reminder", 
        parent: "reminders"
      } },
      component: lazyLoad("protected/organization/Reminder/Edit-reminder")
    },
    {
      path: "/reminders/",
      name: "reminders",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Reminders'},
      component: lazyLoad("protected/organization/Reminder/List-reminder")
    },

    /* Student Behaviors */
    {
      path: "/student/add-behaviors/:student_id",
      name: "add-behaviors",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"student-behavior-add",group:"student-behavior", breadcrumb: {
        label: "Add Behavior", 
        parent: "behaviors"
      } },
      component: lazyLoad("protected/students/student_behaviors/Add-behaviors")
    },
    {
      path: "/student/edit-behaviors/:edit_id/:student_id",
      name: "edit-behaviors",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"student-behavior-edit",group:"student-behavior", breadcrumb: {
        label: "Edit Behavior", 
        parent: "behaviors"
      } },
      component: lazyLoad("protected/students/student_behaviors/Edit-behaviors")
    },
    {
      path: "/student/behaviors-list/:student_id",
      name: "behaviors",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"student-behavior-list",group:"student-behavior", breadcrumb: {
        label: "Behaviors", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_behaviors/List-behaviors-type")
    },    

    /* Behavior Category */
    {
      path: "/behavior-category",
      name: "behavior-category",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"behavior-list", breadcrumb: 'Behavior Category'},
      component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory")
    },
    {
      path: "/edit-behavior-category/:edit_id",
      name: "edit-behavior-category",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"behavior-edit", breadcrumb: {
        label: "Edit Behavior Category", 
        parent: "behavior-category"
      }},
      component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory-edit")
    },
    {
      path: "/add-behavior-category/",
      name: "add-behavior-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"behavior-add", breadcrumb: {
        label: "Add New Behavior Category", 
        parent: "behavior-category"
      } },
      component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory-add")
    },
    {
      path: "/delete-behavior-category",
      name: "delete-behavior-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"behavior-delete", breadcrumb: {
        label: "Delete Behavior Category", 
        parent: "behavior-category"
      }},
      component: lazyLoad("protected/organization/BehaviorCategory/BehaviorCategory-delete")
    },
    
    /* Student Programs */
    {
      path: "/student/program/:student_id",
      name: "student-program-landing",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", group:"student-programs", breadcrumb: 'Programs'},
      component: lazyLoad("protected/students/student_program/Program-Landing")
    },
    {
      path: "/student/program/goal/:student_id",
      name: "student-program-goal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", group:"student-program-goal", breadcrumb: 'Program Target List'},
      component: lazyLoad("protected/students/student_program/Program-Target-List")
    },
    {
      path: "/student/program/category/:category_id/:student_id",
      name: "student-program-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", group:"student-programs", breadcrumb: {
        label: "Programs", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_program/Program-Category-List")
    },
    {
      path: "/student/program/target/:student_id/:category_id",
      name: "student-program-target",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", group:"student-programs"},
      component: lazyLoad("protected/students/student_program/Program-Target-List")
    },

    /* Student Behavior Data Entry*/
    {
      path: "/student/data-entry/:student_id",
      name: "student-data-entry",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-data-entry", breadcrumb: {
        label: "Data Entry", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_behavior_data_entry/data-entry")
    },
    {
       path: "/student/behavior-data-entry/:student_id",
       name: "student-behavior-data-entry",
       meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-data-entry", breadcrumb: {
        label: "Per Behavior Data", 
        parent: "student-data-entry"
        }},
       component: lazyLoad("protected/students/student_behavior_data_entry/behavior-entry")
    },
    {
      path: "/student/abc-data-entry/:student_id",
      name: "student-abc-data-entry",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-data-entry", breadcrumb: {
      label: "ABC Data Entry", 
      parent: "student-data-entry"
      }},
      component: lazyLoad("protected/students/student_behavior_data_entry/abc_data")
   },
   {
      path: "/student/skill-data-entry/:student_id/:encrypt_str?",
      name: "student-skill-data-entry",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-data-entry", breadcrumb: {
      label: "Skill Data Entry", 
      parent: "student-data-entry"
      }},
      component: lazyLoad("protected/students/student_behavior_data_entry/skill_data")
    },
    {
      path: "/student/skill-data-list/:student_id",
      name: "student-skill-data-list",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-data-entry", breadcrumb: {
        label: "Skill Data", 
        parent: "student-skill-data-entry"
        }},
      component: lazyLoad("protected/students/student_behavior_data_entry/skill_data_list")
    },

    /* Student Intake Form Entry*/
    {
      path: "/student/add_intake-forms/:student_id",
      name: "student-add-intake-forms",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student_intake_forms", breadcrumb: {
        label: "Add Intake Form", 
        parent: "student-list-intake-forms"
      }},
      component: lazyLoad("protected/students/student_intake_forms/add-intake-forms")
    },
    {
      path: "/student/list_intake-forms/:student_id",
      name: "student-list-intake-forms",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student_intake_forms", breadcrumb: {
        label: "Intake Form", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_intake_forms/list-intake-forms")
    },
    {
      path: "/student/details_intake-forms/:detail_id/:student_id",
      name: "student-details-intake-forms",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student_intake_forms", breadcrumb: {
        label: "Intake Form Details", 
        parent: "student-list-intake-forms"
      }},
      component: lazyLoad("protected/students/student_intake_forms/detail-intake-forms")
    },

    /* Student Prompt Schedule*/
    {
      path: "/student/prompt-schedule/:student_id",
      name: "student-prompt-schedule",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-prompt-schedule", breadcrumb: {
        label: "Prompt Schedule", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_prompt_schedule/prompt-schedule")
    },

    /* Student Interval Form*/
    {
      path: "/student/interval-form/:student_id",
      name: "student-interval-form",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-interval-form", breadcrumb: {
        label: "Interval Schedule", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_interval_schedule/interval_schedule")
    },

    /* Student Activity History*/
    {
      path: "/student/activity-history/:student_id",
      name: "student-activity-history",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-activity-history", breadcrumb: {
        label: "Activity History", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_activity_history/activity_history")
    },

    {
      path: "/student/workflows/:student_id",
      name: "student-workflows",
      meta: { layout:"ProtectedVue",middleware: "login",sidebar: "profile",group:"student-workflows", breadcrumb: {
        label: "Student Workflows", 
        parent: "student"
      }},
      component: lazyLoad("protected/students/student_workflows/student_workflows")
    },

    /* Student sessions template*/
    {
      path: "/student/sessions/:student_id",
      name: "sessions",
      meta: { layout:"ProtectedVue", middleware: "login", sidebar: "profile", group:"student-session-template"},
      component: lazyLoad("protected/students/sessions")
    },


   /* Program Target Type */
   {
      path: "/program-target-type/add",
      name: "add-program-target-type",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
      component: lazyLoad("protected/organization/ProgramTargetType/Add-Types")
    },
    {
      path: "/program-target-type/edit/:type_id",
      name: "edit-program-target-type",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
      component: lazyLoad("protected/organization/ProgramTargetType/Edit-Types")
    },
    {
      path: "/program-target-types",
      name: "program-target-type-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", breadcrumb: 'Program Target Type'},
      component: lazyLoad("protected/organization/ProgramTargetType/Types")
    },    
    {
      path: "/delete-program-target-types",
      name: "delete-program-target-type",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", breadcrumb: {
        label: "Delete Program Target Types", 
        parent: "program-target-type-list"
      }},
      component: lazyLoad("protected/organization/ProgramTargetType/Delete-Types")
    },


    /* Custom form */
    {
      path: "/form-builder",
      name: "form-builder",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
      component: lazyLoad("protected/CustomFormBuilder/CustomForm")
    },

    /* Intake Form */

    {
      path: "/add-intake-form",
      name: "add-intake-form",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", breadcrumb: {
        label: "Add Intake Form", 
        parent: "intake-form"
      } },
      component: lazyLoad("protected/IntakeForm/addIntakeForm")
    },
    {
      path: "/intake-form",
      name: "intake-form",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", breadcrumb: 'Intake Forms' },
      component: lazyLoad("protected/IntakeForm/IntakeForms")
    },
    {
      path: "/edit-intake-form/:id",
      name: "edit-intake-form",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list", breadcrumb: {
        label: "Edit Intake Form", 
        parent: "intake-form"
      } },
      component: lazyLoad("protected/IntakeForm/editIntakeForm")
    },
    // Intake form fill by guest user
    {
      path: "/fill-intake-form",
      name: "fill-intake-form",
      meta: { layout: "SingleVue" },
      component: lazyLoad("protected/students/student_intake_forms/guestFillIntakeForm")
    },

    /* Templates Form */

    // {
    //   path: "/add-templates",
    //   name: "add-templates",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"program-list" },
    //   component: lazyLoad("protected/Templates/addTemplates")
    // },
    {
      path: "/templates",
      name: "templates",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Templates", breadcrumb: 'Templates' },
      component: lazyLoad("protected/Templates/TemplateList")
    },
    {
      path: "/templates/comment",
      name: "templates-comment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Templates", breadcrumb: {
        label: "Pdf Comment", 
        parent: "templates"
      } },
      component: lazyLoad("protected/Templates/Comments")
    },
    {
      path: "/templates/preview",
      name: "templates-preview",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Pdf Preview", 
        parent: "templates"
      } },
      component: lazyLoad("protected/Templates/PreviewPdfTemplate")
    },
    {
      path: "/templates/customer-preview/:template_id",
      name: "customer-preview",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Pdf Preview", 
        parent: "templates"
      } },
      component: lazyLoad("protected/Templates/CustomerPreview")
    },
    {
      path: "/document-label-customize",
      name: "document-label-customize",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Document Labels Customize", 
        parent: "document-settings"
      } },
      component: lazyLoad("protected/Templates/DocumentLabelCustomize")
    },
    {
      path: "/customer-agreement/:template_id",
      name: "customer-agreement",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Agreement", 
        parent: "templates"
      } },
      component: lazyLoad("protected/Templates/CustomerAgreement")
    },
    {
      path: "/admin-final-preview",
      name: "admin-final-preview",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Admin Preview", 
        parent: ""
      } },
      component: lazyLoad("protected/Templates/AdminFinalPreview")
    },  
    {
      path: "/admin-final-preview-word",
      name: "admin-final-preview-word",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Admin Preview Word", 
        parent: ""
      } },
      component: lazyLoad("protected/Templates/AdminFinalPreviewWord")
    },
    {
      path: "/templates/word-document/comment",
      name: "templates-word-comment",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Templates", breadcrumb: {
        label: "Word Comment", 
        parent: "templates"
      } },
      component: lazyLoad("protected/Templates/WordDocument/Comments")
    },
    {
      path: "/merge-field",
      name: "merge-field",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Templates", breadcrumb: 'Merge Field' },
      component: lazyLoad("protected/Templates/MergeField/MergeField")
    },
    {
      path: "/client-portal/:token",
      name: "client-portal",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Document Preview", 
        parent: ""
      } },
      component: lazyLoad("protected/Templates/EndUserView/EndScreen")
    },
    {
      path: "/client-details-portal/:token/:id",
      name: "client-details-portal",
      meta: { layout: "SingleVue", breadcrumb: {
        label: "Document Details Preview", 
        parent: ""
      } },
      component: lazyLoad("protected/Templates/EndUserView/EndScreenDetails")
    },

    // Case Documents
    {
      path: "/case-document-list",
      name: "case-document-list",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: 'Case Documents' },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentList")
    },
    {
      path: "/deleted-case-documents",
      name: "deleted-case-documents",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
        label: "Deleted Case Documents", 
        parent: "case-document-list"
      } },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentsDeletedList")
    },
    {
      path: "/case-document-add",
      name: "case-document-add",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
          label: "Case Document Add",
          parent: "case-document-list"
        } 
      },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentAdd")
    },
    {
      path: "/case-document-edit/:case_id",
      name: "case-document-edit",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
          label: "Case Document Edit",
          parent: "case-document-list"
        } 
      },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentEdit")
    },

    {
      path: "/case-document-details/:case_id",
      name: "case-document-details",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
          label: "Case Document Details",
          parent: "case-document-list"
        }
      },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentDetails")
    },

    // {
    //   path: "/case-document/:tab_name/:case_id",
    //   name: "case-document-info",
    //   meta: {
    //     layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "program-list", breadcrumb: {
    //       label: "Case Document info",
    //       parent: "case-document-list"
    //     }
    //   },
    //   component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentDetails")
    // },


    {
      path: "/case-document-templates/:case_id",
      name: "case-document-templates",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
          label: "Case Document Templates",
          parent: "case-document-list"
        }
      },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentTemplates")
    },
    {
      path: "/case-document-deleted-templates/:case_id",
      name: "case-document-deleted-templates",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
          label: "Case Document Deleted Templates",
          parent: "case-document-list"
        }
      },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentDeletedTemplates")
    },

    {
      path: "/case-document-goal-convert-list/:import_file_code/:case_id",
      name: "case-document-goal-convert-list",
      meta: {
        layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "Templates", breadcrumb: {
          label: "Case Document Goal Convert List",
          parent: "case-document-list"
        }
      },
      component: lazyLoad("protected/Templates/CaseDocument/CaseDocumentGoalConvertList")
    },


    // Intake form fill by guest user
    {
      path: "/fill-templates",
      name: "fill-templates",
      meta: { layout: "ProtectedVue" },
      component: lazyLoad("protected/Templates/students/student_intake_forms/guestFillIntakeForm")
    },

    /* Session Form Category */
    {
      path: "/session-form/category/add/:parent_id?",
      name: "add-sessionform-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Add Note Helper Category", 
        parent: "sessionform-category"
      } },
      component: lazyLoad("protected/SessionTemplates/Category/Add-Category")
    },
    {
      path: "/session-form/category/edit/:cid",
      name: "edit-sessionform-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
        label: "Edit Note Helper Category", 
        parent: "sessionform-category"
      } },
      component: lazyLoad("protected/SessionTemplates/Category/Edit-Category")
    },
    {
      path: "/session-form/category/:parent_id?",
      name: "sessionform-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Note Helper Category'},
      component: lazyLoad("protected/SessionTemplates/Category/List-Category")
    },    
    {
      path: "/session-form/category/delete",
      name: "delete-sessionform-category",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list"},
      component: lazyLoad("protected/SessionTemplates/Category/Delete-Category")
    },

    /* Skill Area */
    {
      path: "/skillareas",
      name: "skillareas",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: 'Skill Area' },
      component: lazyLoad("protected/organization/SkillArea/skill-area-list")
    },
    {
      path: "/add-skillarea",
      name: "add-skillarea",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-add", breadcrumb: {
        label: "Add New Skill Area", 
        parent: "skillareas"
      } },
      component: lazyLoad("protected/organization/SkillArea/skill-area-add")
    },
    {
      path: "/edit-skillarea/:skill_id",
      name: "edit-skillarea",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-edit", breadcrumb: {
        label: "Edit Skill Area", 
        parent: "skillareas"
      } },
      component: lazyLoad("protected/organization/SkillArea/skill-area-edit")
    },
    {
      path: "/delete-skillarea",
      name: "delete-skillarea",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
        label: "Deleted Skill Area", 
        parent: "skillareas"
      } },
      component: lazyLoad("protected/organization/SkillArea/skill-area-delete")
    },
    {
      path: "/import-skillarea",
      name: "import-skillarea",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
        label: "Import Skill Area", 
        parent: "skillareas"
      }},
      component: lazyLoad("protected/organization/SkillArea/skill-area-import")
    },
    /* BCBA Task */
    {
      path: "/bcbatasks",
      name: "bcbatasks",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: 'BCBA Task' },
      component: lazyLoad("protected/organization/BCBATask/bcba-task-list")
    },
    {
      path: "/add-bcbatask",
      name: "add-bcbatask",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-add", breadcrumb: {
        label: "Add New BCBA Task", 
        parent: "bcbatasks"
      } },
      component: lazyLoad("protected/organization/BCBATask/bcba-task-add")
    },
    {
      path: "/edit-bcbatask/:task_id",
      name: "edit-bcbatask",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-edit", breadcrumb: {
        label: "Edit BCBA Task", 
        parent: "bcbatasks"
      } },
      component: lazyLoad("protected/organization/BCBATask/bcba-task-edit")
    },
    {
      path: "/delete-bcbatask",
      name: "delete-bcbatask",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
        label: "Deleted BCBA Task", 
        parent: "bcbatasks"
      } },
      component: lazyLoad("protected/organization/BCBATask/bcba-task-delete")
    },
    {
      path: "/import-bcbatask",
      name: "import-bcbatask",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list", breadcrumb: {
        label: "Import BCBA Task", 
        parent: "bcbatasks"
      }},
      component: lazyLoad("protected/organization/BCBATask/bcba-task-import")
    },

    /* Session Template */
    {
      path: "/session-template",
      name: "session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list" },
      component: lazyLoad("protected/organization/SessionTemplate/session-template-list")
    },
    {
      path: "/add-session-template",
      name: "add-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-add" },
      component: lazyLoad("protected/organization/SessionTemplate/session-template-add")
    },
    {
      path: "/edit-session-template/:st_id",
      name: "edit-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-edit" },
      component: lazyLoad("protected/organization/SessionTemplate/session-template-edit")
    },
    {
      path: "/delete-session-template",
      name: "delete-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"hcodes-list" },
      component: lazyLoad("protected/organization/SessionTemplate/session-template-delete")
    },

    /* Target Accuracy */
    {
      path: "/target-accuracy",
      name: "target-accuracy",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: 'Target Acuracy' },
    component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-list")
    },
    {
      path: "/add-target-accuracy",
      name: "add-target-accuracy",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-add", breadcrumb: {
        label: "Add New Target Accuracy", 
        parent: "target-accuracy"
      } },
      component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-add")
    },
    {
      path: "/edit-target-accuracy/:ta_id",
      name: "edit-target-accuracy",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-edit", breadcrumb: {
        label: "Edit Target Accuracy", 
        parent: "target-accuracy"
      } },
      component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-edit")
    },
    {
      path: "/delete-target-accuracy",
      name: "delete-target-accuracy",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: {
        label: "Deleted Target Acuracy", 
        parent: "target-accuracy"
      } },
      component: lazyLoad("protected/organization/TargetAccuracy/target-accuracy-delete")
    },
    /* Target Prompt Code */
    {
      path: "/target-promo-code",
      name: "target-promo-code",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: 'Target Promo Code' },
      component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-list")
    },
    {
      path: "/add-target-promo-code",
      name: "add-target-promo-code",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-add", breadcrumb: {
        label: "Add New Target Promo Code", 
        parent: "target-promo-code"
      } },
      component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-add")
    },
    {
      path: "/edit-target-promo-code/:tpc_id",
      name: "edit-target-promo-code",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-edit", breadcrumb: {
        label: "Edit Target Promo Code", 
        parent: "target-promo-code"
      } },
      component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-edit")
    },
    {
      path: "/delete-target-promo-code",
      name: "delete-target-promo-code",
      meta: { layout: "ProtectedVue", middleware: "login", sidebar: "profile", user_permission: "hcodes-list", breadcrumb: {
        label: "Deleted Target Promo Code", 
        parent: "target-promo-code"
      } },
      component: lazyLoad("protected/organization/TargetPromoCode/target-promo-code-delete")
    },

    /* School */
    {
      path: "/school",
      name: "school",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: 'Schools' },
      component: lazyLoad('protected/organization/School/School')
    },
    {
      path: "/add-school",
      name: "add-school",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: {
        label: "Add New School", 
        parent: "school"
      } },
      component: lazyLoad("protected/organization/School/Add-school")
    },
    {
      path: "/edit-school/:school_id",
      name: "edit-school",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: {
        label: "Edit School", 
        parent: "school"
      }},
      component: lazyLoad("protected/organization/School/Edit-school")
    },
    {
      path: "/school-principal/:school_id",
      name: "school-principal",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: {
        label: "School Principal", 
        parent: "school"
      }},
      component: lazyLoad("protected/organization/School/SchoolPrincipal")
    },
    {
      path: "/school-contact/:school_id",
      name: "school-contact",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: {
        label: "School Contact", 
        parent: "school"
      }},
      component: lazyLoad("protected/organization/School/SchoolContact")
    },
    {
      path: "/school-holidays/:school_id",
      name: "school-holidays",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: {
        label: "School Holidays", 
        parent: "school"
      }},
      component: lazyLoad("protected/organization/School/Holiday-Calendar")
    },
    {      
      path: "/delete-school",
      name: "delete-school",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"School", breadcrumb: {
        label: "Delete School", 
        parent: "school"
      }},
      component: lazyLoad("protected/organization/School/Delete-school")
    },

    /* Coverage types */
    {
      path: "/coverage-types",
      name: "coverage-types",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"agency-list", breadcrumb: 'Coverage Types' },
      component: lazyLoad('protected/organization/CoverageTypes/CoverageTypes')
    },
    {
      path: "/add-coverage-types",
      name: "add-coverage-types",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"agency-add", breadcrumb: {
        label: "Add New Coverage Type", 
        parent: "coverage-types"
      } },
      component: lazyLoad("protected/organization/CoverageTypes/Add-CoverageTypes")
    },
    {
      path: "/edit-coverage-types/:coverage_type_id",
      name: "edit-coverage-types",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"agency-edit", breadcrumb: {
        label: "Edit Coverage Type", 
        parent: "coverage-types"
      }},
      component: lazyLoad("protected/organization/CoverageTypes/Edit-CoverageTypes")
    },
    {      
      path: "/delete-coverage-types",
      name: "delete-coverage-types",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"agency-list", breadcrumb: {
        label: "Delete Coverage Type", 
        parent: "coverage-types"
      }},
      component: lazyLoad("protected/organization/CoverageTypes/Delete-CoverageTypes")
    },

    /* Reinforcement */
    {
      path: "/reinforcement/add/:parent_id?",
      name: "add-reinforcement",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Reinforcement Category", 
        parent: "reinforcement"
      } },
      component: lazyLoad("protected/organization/Reinforcement/Add-Reinforcement")
    },
    {
      path: "/reinforcement/edit/:cid",
      name: "edit-reinforcement",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
        label: "Reinforcement Category", 
        parent: "reinforcement"
      } },
      component: lazyLoad("protected/organization/Reinforcement/Edit-Reinforcement")
    },
    {
      path: "/reinforcement/:parent_id?",
      name: "reinforcement",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Reinforcement Category'},
      component: lazyLoad("protected/organization/Reinforcement/List-Reinforcement")
    },    
    {
      path: "/reinforcement/delete",
      name: "delete-reinforcement",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: {
        label: "Deleted Reinforcement", 
        parent: "reinforcement"
      }},
      component: lazyLoad("protected/organization/Reinforcement/Delete-Reinforcement")
    },

    /* Group Template Lession */
    {
      path: "/group-template-lession/add/:parent_id?",
      name: "add-group-template-lession",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Group Template Lession Category", 
        parent: "group-template-lession"
      } },
      component: lazyLoad("protected/organization/GroupTemplateLession/Add-Lession")
    },
    {
      path: "/group-template-lession/edit/:cid",
      name: "edit-group-template-lession",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
        label: "Edit Group Template Lesson Category", 
        parent: "group-template-lession"
      } },
      component: lazyLoad("protected/organization/GroupTemplateLession/Edit-Lession")
    },
    {
      path: "/group-template-lession/:parent_id?",
      name: "group-template-lession",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Group Template Lesson Category'},
      component: lazyLoad("protected/organization/GroupTemplateLession/List-Lession")
    },    
    {
      path: "/group-template-lession/delete",
      name: "delete-group-template-lession",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: {
        label: "Deleted Group Template Lesson", 
        parent: "group-template-lession"
      }},
      component: lazyLoad("protected/organization/GroupTemplateLession/Delete-Lession")
    },

    /* Prompt Code */
    {
      path: "/prompt-code/add",
      name: "add-prompt-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Add Prompt Code", 
        parent: "prompt-code"
      } },
      component: lazyLoad("protected/organization/PromptCode/add-promptcode")
    },
    {
      path: "/prompt-code/edit/:cid",
      name: "edit-prompt-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-edit", breadcrumb: {
        label: "Edit Prompt Code", 
        parent: "prompt-code"
      } },
      component: lazyLoad("protected/organization/PromptCode/edit-promptcode")
    },
    {
      path: "/prompt-code/",
      name: "prompt-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list", breadcrumb: 'Prompt Code'},
      component: lazyLoad("protected/organization/PromptCode/promptcode-list")
    },    
    {
      path: "/prompt-code/delete",
      name: "delete-prompt-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list"},
      component: lazyLoad("protected/organization/PromptCode/promptcode-delete")
    },
    {
      path: "/invoice",
      name: "invoice",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-list"},
      component: lazyLoad("protected/organization/Invoice/invoice")
    },

    /* Auto Generate Session */
    {
      path: "/auto-generate-session",
      name: "auto-generate-session",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Auto Generate Session Notes' },
      component: lazyLoad("protected/organization/AutoGenerateSessionNotes/AutoGenerateSession")
    },
    {
      path: "/sessions-queue",
      name: "sessions-queue",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Sessions Queue' },
      component: lazyLoad("protected/organization/Autoor/SessionsQueue")
    },
    {
      path: "/auto-generate-session-import",
      name: "auto-generate-session-import",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Session Data Import' },
      component: lazyLoad("protected/organization/AutoGenerateSessionNotes/AutoGenerateImport")
    },
    {
      path: "/pending-target-data",
      name: "pending-target-data",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Pending Target Data' },
      component: lazyLoad("protected/organization/AutoGenerateSessionNotes/PendingTargetData")
    },
    // {
    //   path: "/session-report",
    //   name: "session-report",
    //   meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Session Report' },
    //   component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SessionReport")
    // },
    {
      path: "/invoice-import-list",
      name: "invoice-import-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session" },
      component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SessionDatas")
    },
    {
      path: "/sheets-link",
      name: "sheets-link",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Sheets Link' },
      component: lazyLoad("protected/organization/AutoGenerateSessionNotes/SheetsLink")
    },
    // Dynamic session Template
    {
      path: "/dynamic-session-template",
      name: "dynamic-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'Session Templates' },
      component: lazyLoad("protected/organization/DynamicSessionTemplate/listSessionTemplate")
    },
    {
      path: "/dynamic-session-template/add",
      name: "add-dynamic-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
        label: "Add Session Template", 
        parent: "dynamic-session-template"
      } },
      component: lazyLoad("protected/organization/DynamicSessionTemplate/addSessionTemplate")
    },
    {
      path: "/dynamic-session-template/edit/:temp_id",
      name: "edit-dynamic-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
        label: "Edit Session Template", 
        parent: "dynamic-session-template"
      } },
      component: lazyLoad("protected/organization/DynamicSessionTemplate/editSessionTemplate")
    },
    {
      path: "/dynamic-session-template/preview/:temp_id",
      name: "preview-dynamic-session-template",
      meta: { layout: "SingleVue",middleware: "login", user_permission:"Auto Generate Session"},
      component: lazyLoad("protected/organization/DynamicSessionTemplate/previewSessionTemplate")
    },
    // Dynamic Pdf Session Template
    {
      path: "/dynamic-pdf-session-template",
      name: "dynamic-pdf-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: 'PDF Session Templates' },
      component: lazyLoad("protected/organization/DynamicSessionTemplate/PdfListSessionTemplate")
    },
    {
      path: "/dynamic-pdf-session-template/add",
      name: "add-dynamic-pdf-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
        label: "Add Pdf Session Template", 
        parent: "dynamic-pdf-session-template"
      } },
      component: lazyLoad("protected/organization/DynamicSessionTemplate/PdfAddSessionTemplate")
    },
    {
      path: "/dynamic-pdf-session-template/edit/:temp_id",
      name: "edit-dynamic-pdf-session-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Auto Generate Session", breadcrumb: {
        label: "Edit Pdf Session Template", 
        parent: "dynamic-pdf-session-template"
      } },
      component: lazyLoad("protected/organization/DynamicSessionTemplate/PdfEditSessionTemplate")
    },

    //invoice data
    {
      path: "/invoice-list",
      name: "invoice-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Invoice List / Import / Export ", breadcrumb: 'Invoice Data Import/Export' },
      component: lazyLoad("protected/organization/Invoice/invoice-list")
    },
    {
      path: "/invoice-setss-request",
      name: "invoice-setss-request",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"Request SETSS Invoice", breadcrumb: 'Invoice Data Import/Export' },
      component: lazyLoad("protected/organization/Invoice/SetssRequest")
    },
    {
      path: "/invoice-report",
      name: "invoice-report",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", user_permission:"Invoice Report", breadcrumb: 'Invoice Report' },
      component: lazyLoad("protected/organization/Invoice/InvoiceReport")
    },

    //mass-goal-import
    {
      path: "/mass-goal-import",
      name: "mass-goal-import",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Import", breadcrumb: 'Goal Import' },
      component: lazyLoad("protected/organization/Goal/mass-goal-import-list")
    },

    //mass-goal-import-edit
    {
      path: "/mass-goal-edit/:goal_id",
      name: "mass-goal-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Goal Import", breadcrumb: {
        label: "Mass Goal Edit", 
        parent: "mass-goal-import"
      } },
      component: lazyLoad("protected/organization/Goal/mass-goal-import-edit")
    },

    //User Log list-details
    {
      path: "/reports/web-pages-applications",
      name: "web-pages-applications",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Monitoring", breadcrumb: 'Web Monitoring List' },
      component: lazyLoad("protected/Monitoring/Web-page-logs")
    },
    {
      path: "/reports/student-activity-history",
      name: "activity-history",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Activity Log", breadcrumb: 'Activity History' },
      component: lazyLoad("protected/Monitoring/Student-activity-history")
    },
    {
      path: "/reports/import-file-log-history",
      name: "import-file-log",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Import file log list' },
      component: lazyLoad("protected/Monitoring/ImportLogHistory")
    },
    {
      path: "/reports/import-file-details-log-history/:source_id",
      name: "import-file-details-log",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Details Import File Data List", 
        parent: "import-file-log"
      } },
      component: lazyLoad("protected/Monitoring/ImportLogDetailsHistory")
    },

    //Graph Module
    {
      path: "/graph/abc-behavior",
      name: "graph-abc-behavior",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
      component: lazyLoad("protected/Graph/StudentsAbcBehavior")
    },
    {
      path: "/graph/student-behavior",
      name: "graph-student-behavior",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
      component: lazyLoad("protected/Graph/StudentsBehavior")
    },
    {
      path: "/graph/student-skill-behavior",
      name: "graph-student-skill-behavior",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Students Behavior Graph", breadcrumb: 'Graph' },
      component: lazyLoad("protected/Graph/StudentSkillBehavior")
    },
    {
      path: "/graph/student-note-helper-graph",
      name: "student-note-helper-graph",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Students Note Helper Graph", breadcrumb: 'Graph' },
      component: lazyLoad("protected/Graph/PercentageGraph")
    },
    {
      path: "/graph/fluency-rate",
      name: "graph-student-fluency-rate",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
      component: lazyLoad("protected/Graph/FluencyGraph")
    },
    {
      path: "/graph/data-import-list",
      name: "graph-data-import-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Graph' },
      component: lazyLoad("protected/Graph/GraphDataImportList")
    },

    //Work Flows
    {
      path: "/workflows",
      name: "work-flows",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: 'Workflows' },
      component: lazyLoad("protected/Workflows/WorkFlowsDataList")
    },
    {
      path: "/add-workflows/:id",
      name: "add-work-flows",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"certification-add", breadcrumb: {
        label: "Edit Workflow", 
        parent: "work-flows"
      }},
      component: lazyLoad("protected/Workflows/AddWorkFlowData")
    },

    //Pdf Document Editor
    {
      path: "/pdf-template-editor",
      name: "pdf-template-editor",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"organization-list", breadcrumb: 'Pdf Document Editor' },
      component: lazyLoad("protected/PdfDocumentEditor")
    },

    // Groups
    {
      path: "/groups",
      name: "groups",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"organization-list", breadcrumb: 'Groups' },
      component: lazyLoad("protected/organization/Group/GroupList")
    },
    {
      path: "/groups/add",
      name: "group-add",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"organization-list", breadcrumb: {
        label: "Add Group", 
        parent: "groups"
      } },
      component: lazyLoad("protected/organization/Group/GroupAdd")
    },
    {
      path: "/groups/edit/:group_id",
      name: "group-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"organization-list", breadcrumb: {
        label: "Edit Group", 
        parent: "groups"
      } },
      component: lazyLoad("protected/organization/Group/GroupEdit")
    },

    // Classes
    {
      path: "/classes",
      name: "classes",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Classes", breadcrumb: 'Classes' },
      component: lazyLoad("protected/organization/Classes/ClassList")
    },
    {
      path: "/classes/add",
      name: "class-add",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Classes", breadcrumb: {
        label: "Add Class", 
        parent: "classes"
      } },
      component: lazyLoad("protected/organization/Classes/ClassAdd")
    },
    {
      path: "/classes/edit/:class_id",
      name: "class-edit",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Classes", breadcrumb: {
        label: "Edit Class", 
        parent: "classes"
      } },
      component: lazyLoad("protected/organization/Classes/ClassEdit")
    },

    {
      path: "/staff-document-preview/:file_id",
      name: "staff-document-preview",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"organization-list", breadcrumb: {
        label: "Document Preview", 
        parent: "profile"
      } },
      component: lazyLoad("protected/StaffDocumentsPreview")
    },

    {
      path: "/profile-document-preview",
      name: "profile-document-preview",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile", breadcrumb: {
        label: "Document Preview", 
        parent: "profile"
      } },
      component: lazyLoad("protected/ProfileDocumentPreview")
    },
    // Error List
    {
      path: "/error-list",
      name: "error-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Error List", breadcrumb: 'Error List' },
      component: lazyLoad("protected/ErrorListing/ErrorList")
    },
    {
      path: "/template-short-code",
      name: "template-short-code",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Template Short Code", breadcrumb: 'Short Code' },
      component: lazyLoad("protected/Goal/ConversionV2/ShortCode")
    },
    {
      path: "/goal-generator/conversion-template-list",
      name: "conversion-template-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"TP Conversion V-II", breadcrumb: 'Conversion Template' },
      component: lazyLoad("protected/Goal/ConversionV2/ConversionTemplateListV2")
    },
    {
      path: "/goal-generator/add-conversion-template-list",
      name: "add-conversion-template-list",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"TP Conversion V-II", breadcrumb: 'Add Conversion Template' },
      component: lazyLoad("protected/Goal/ConversionV2/AddConversionTemplateListV2")
    },
    {
      path: "/goal-generator/edit-conversion-template-list/:u_id",
      name: "edit-conversion-template",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"TP Conversion V-II", breadcrumb: 'Edit Conversion Template' },
      component: lazyLoad("protected/Goal/ConversionV2/EditConversionTemplateV2")
    },
    {
      path: "/sample-files",
      name: "sample-files",
      meta: { layout: "ProtectedVue",middleware: "login",sidebar: "profile",user_permission:"Sample Files", breadcrumb: 'Sample Files' },
      component: lazyLoad("protected/SampleFiles/SampleFiles")
    }
  ]
});
router.beforeEach((to, from, next) => {
  if (_.isEmpty(to.meta)) {
    return router.push({ name: 'home' });
  }
  if(to.meta.middleware == "login"){
    // console.log('to.meta.user_permission', to.meta.user_permission)
    if (localStorage.getItem('token') === '') {
      return router.push({ name: 'home' });
    }
    if(to.meta.user_permission){
      let userPermission = JSON.parse(localStorage.getItem('userPermission'));  
      let permission = false;
      let userType = JSON.parse(localStorage.getItem('userType'));
      if (userType != 'Super Admin') {
        if(userPermission) {
          for (var i=0; i < userPermission.length; i++) {
            if ((userPermission[i].label == to.meta.user_permission) || (userPermission[i].subLabel == to.meta.user_permission)) {
              permission = true;          
              break;
            }        
          }
        } else {
          return router.push({ name: 'home' });
        }
      } else {
        permission = true;
        // return router.push({ name: 'home' });
      }
      if(!permission){
        return router.push({ name: '403' });
      }
    }    
  }
  return next();
})

export default router;
